<template>
  <div class="table-item">
    <div class="table-item__row">
      <div class="table-item__row-label">
        Name:
      </div>
      {{ tableItemData.username }}
    </div>
    <div class="table-item__row">
      <div class="table-item__row-label">
        Email:
      </div>
      {{ tableItemData.email }}
    </div>
    <div class="table-item__row table-item__row--auto">
      <div class="table-item__row-label">
        Balance:
      </div>
      {{ tableItemData.balance }}
    </div>
    <div class="table-item__row table-item__row--auto">
      <GlInput
        v-model="topUpValue"
        class="table-item__input"
        :height="40"
      />
    </div>
    <div class="table-item__row table-item__row--auto">
      <gl-button
        dark
        full
        style="height: 40px"
        title="Top up"
        @click="topUpClick"
      />
    </div>
  </div>
</template>
<script>
import GlButton from '@/components/gl-button'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlButton,
    GlInput,
  },
  props: {
    tableItemData: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      topUpValue: null,
    }
  },
  watch: {
    topUpValue(newVal) {
      if(newVal) {
        this.topUpValue = newVal.replace(/\D/g, '')
      }
    },
  },
  methods: {
    topUpClick() {
      this.$emit('top-up-click', {
        userId: this.tableItemData._id,
        balance: parseInt(this.topUpValue)
      })
      
      this.topUpValue = null
    },
  },
}
</script>
<style scoped>
.table-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
}
.table-item::v-deep .gl-input__input {
  width: 100%;
  background-color: var(--white) !important;
  border: 1px solid var(--reflex-bluet);
}
.table-item + .table-item {
  border-top: 1px solid var(--dark-grey-d-3);
}
.table-item__row {
  flex: 1 0 20%;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-item__row--auto {
  flex: 0 0 100px;
  max-width: 100%;
}
.table-item__input {
  width: 100%;
}
.table-item__input::v-deep .gl-input__input {
  background-color: var(--pale-blue);
}
.table-item__row-label {
  display: none;
  font-weight: 700;
}

@media (max-width: 1023px) {
  .table-item {
    flex-wrap: wrap;
  }
  .table-item__row:not(.table-item__row--auto) {
    flex: 1 0 100%;
  }
  .table-item__row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .table-item__row-label {
    display: block;
  }
  .table-item__row:nth-child(3) {
    flex: 0 0 100%;
  }
}
@media (max-width: 767px) {
  .table-item__row {
    display: block;
  }
  .table-item__row--auto {
    flex: 0 0 calc(50% - 8px);
  }
}
</style>