<template>
  <div class="admin-panel__content-wrapper">
    <div class="admin-panel__content">
      <div class="admin-panel__content-header">
        <div class="admin-panel__content-header-row">
          Name
        </div>
        <div class="admin-panel__content-header-row">
          Email
        </div>
        <div class="admin-panel__content-header-row admin-panel__content-header-row--auto">
          Balance
        </div>
        <div class="admin-panel__content-header-row admin-panel__content-header-row--auto" />
        <div class="admin-panel__content-header-row admin-panel__content-header-row--auto" />
      </div>
      <template v-if="usersData.length">
        <UserTableItem 
          v-for="user in usersData"
          :key="user._id"
          :table-item-data="user" 
          @top-up-click="sendNewValue" 
        />
      </template>
      <template v-else>
        <div class="admin-panel__content-empty">
          There is no data for view
        </div>
      </template>
    </div>
    <div class="admin-panel__pagination">
      <Paginator 
        v-if="adminPanelUsersTotal > adminPanelUsersPageSize"
        ref="paginatorUsers"
        :rows="adminPanelUsersPageSize" 
        :total-records="adminPanelUsersTotal"
        @page="pageChange" 
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapActions } from 'vuex'

// Components
import UserTableItem from './components/UserTableItem.vue'
import Paginator from 'primevue/paginator'

// Utils
import { scrollToSelector } from '@/utils/scroll-to'

export default {
  name: 'AdminPanelUsers',
  components: {
    UserTableItem,
    Paginator,
  },
  data() {
    return {
      pageNumber: 1,
    }
  },
  computed: {
    ...mapGetters('adminPanel', ['usersData', 'adminPanelUsersPageSize', 'adminPanelUsersTotal', 'adminFilterValue']),
  },
  watch: {
    adminFilterValue() {
      if(this.$refs.paginatorUsers) {
        this.$refs.paginatorUsers.changePage(0)
      } else {
        this.sendAllParams()
      }
    }
  },
  mounted() {
    this.sendAllParams()
  },
  methods: {
    ...mapActions('adminPanel', ['getUsers', 'topUpUser']),
    ...mapMutations('adminPanel', ['SET_FILTER_VALUE', 'SWITCH_FILTER_CLEAR_TRIGGER']),
    scrollToSelector,
    scrollToSelectorItem(wrap, aim) {
      this.scrollToSelector(
        wrap,
        aim,
        100,
      )
    },
    sendNewValue(userObject) {
      const updatedUserObject = {
          ...userObject,
          pageNumber: this.pageNumber
      }

      this.SWITCH_FILTER_CLEAR_TRIGGER()
      
      this.topUpUser(updatedUserObject)
    },
    pageChange({ page }) {
      if(page > 0) {
        this.scrollToSelectorItem('.main', '.admin-panel__content-wrapper')
      }
      this.pageNumber = page + 1
      this.sendAllParams()
    },
    sendAllParams() {
      this.getUsers(
        {
          pageNumber: this.pageNumber,
          email: this.adminFilterValue,
        }
      )
    }
  },
}
</script>

<style scoped>
.admin-panel__content-header-row {
  flex: 1 0 20%;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.admin-panel__content-header-row--auto {
  flex: 0 0 100px;
  max-width: 100%;
}
</style>